export const DEBUG = process.env.NODE_ENV === 'development';

export const baseURL = DEBUG
  ? 'http://localhost:7001'
  : process.env.VUE_APP_API_ORIGIN;

export const studentFileS3BucketUrl =
  process.env.VUE_APP_S3_BUCKET_STUDENT_FILES;

export const sortPartOptions = [
  { text: 'Name', value: 0 },
  { text: 'Price: Low to High', value: 1 },
  { text: 'Price: High to Low', value: 2 },
];

export const sortToolOptions = [
  { text: 'Name', value: 0 },
  { text: 'Availability: Low to High', value: 1 },
  { text: 'Availability: High to Low', value: 2 },
];

export const statusOptions = [
  { text: 'Rejected', value: -1 },
  { text: 'Placed', value: 0 },
  { text: 'Filled', value: 1 },
  { text: 'Picked Up', value: 2 },
];

export const roleOptions = [
  'Admin',
  'Store',
  'Proteus Developer',
  'Laser Cutting',
  '3D Printing',
];

export const productMaterialTypes = [
  {
    text: '1/8 Inch Acrylic',
    value: 0,
    costPerUnitArea: 0.05,
    fileText: 'OneEigthInchAcrylic',
  },
  {
    text: '1/4 Inch Acrylic [Not Supported]',
    value: 1,
    costPerUnitArea: 0.06,
    fileText: 'OneQuarterInchAcrylic',
  },
  {
    text: 'Medium Density Fiberboard [Not Supported]',
    value: 2,
    costPerUnitArea: 0.04,
    fileText: 'MDF',
  },
  {
    text: 'Plywood',
    value: 3,
    costPerUnitArea: 0.04,
    fileText: 'Plywood',
  },
];

export enum OrderItemStatus {
  Rejected = -1,
  Placed = 0,
  Filled = 1,
  PickedUp = 2,
  InProgress = 5,
}

export enum ProteusStatus {
  Working = 'Working',
  Broken = 'Broken',
  Distributed = 'Distributed',
  Development = 'Development',
  Unknown = 'Unknown',
}

export const ProteusIssuePrompts = [
  'Proteus does not turn on or does not stay powered on',
  'Screen does not turn on',
  'Broken or malfunctioning SD port',
  'Broken acrylic',
  'Motor or I/O Port(s) broken (please note which)',
  'Problems running or loading code',
  'Other (please describe)',
];

export const ProteusIssueTypes = [
  'Power Issues',
  'LCD',
  'SD Port',
  'Voltage Regulator',
  'Acrylic',
  'Propeller',
  'XBee',
  'Motor Ports',
  'I/O Ports',
  'Accelerometer',
  'Running/Loading Code',
  'Unknown',
  'Uncategorized',
];

export const pointerPoem =
  'aPointerIsAVariableThatHasAsItsValueTheAddressOfAnotherVariable';

export const startingBudget = 160.0;
export const startingFilament = 100.0;

// (Possible) year-to-year changes
export const sectionTimeOptions = [
  '8:00 AM',
  '9:10 AM',
  '10:20 AM',
  '11:30 AM',
  '12:40 PM',
];

export const sectionRoomOptions = ['HI 206', 'HI 214', 'HI 216'];

export const keycodeLength = 5;

export default {
  DEBUG,
  baseURL,
  statusOptions,
};
